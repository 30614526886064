var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[(_vm.refreshStatus)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.pageDetailHide),expression:"pageDetailHide"}]},[_c('div',{staticClass:"edp-rank-out"},[_c('edp-header-search',{attrs:{"headerLeftWord":_vm.$t('rank.headerLeftWord'),"searchPlaceHolder":_vm.$t('rank.searchPlaceholder'),"noSearch":"true"}},[_c('div',{attrs:{"slot":"bottom"},slot:"bottom"},[_c('rank-top',{attrs:{"list":_vm.rankTopList},on:{"changeRankType":_vm.changeRankTypeFn}})],1)]),_c('div',{staticClass:"topTips fac"},[_c('img',{staticClass:"tip",attrs:{"src":require("@/src/assets/member/notice.png"),"alt":""}}),_c('span',{staticClass:"f14 op5"},[_vm._v(" "+_vm._s(_vm.$t("rank.pageTipsWord")))]),(_vm.rankTopSelected == 2)?_c('div',{staticClass:"topTips__right"},[_vm._v(" "+_vm._s(_vm.$t("rank.reportTime"))+" ")]):_vm._e()]),(_vm.rankTopSelected == 2)?_c('rank-cate',{staticClass:"edp-rank-out__sk",attrs:{"list":_vm.rankCateList}}):_vm._e(),(_vm.memberDepartSelected === 'member' && _vm.rankTopSelected != 2)?_c('member-rank-board',{ref:"memberRl",staticClass:"edp-rank-out__fx",on:{"qes_click":_vm.qes_clickFn}}):_vm._e(),(_vm.memberDepartSelected === 'member' && _vm.rankTopSelected == 2)?_c('div',{staticClass:"edp-rank-frame"},[_c('iframe',{attrs:{"src":`${_vm.urls}board`}})]):_vm._e(),(_vm.memberDepartSelected === 'depart' && _vm.rankTopSelected == 2)?_c('div',{staticClass:"edp-rank-frame"},[_c('iframe',{attrs:{"src":`${_vm.urls}departmentBoard`}})]):_vm._e()],1),_c('edp-dialog',{ref:"qesDialog",staticClass:"rank-dialog"},[_c('div',{staticClass:"qesDialog-article",attrs:{"slot":"content"},slot:"content"},[_c('edpWaterBg',{attrs:{"percent":"20%","opacity":"0.6"}}),_c('div',{staticStyle:{"position":"relative","width":"100%","z-index":"1"}},[_c('em',{domProps:{"innerHTML":_vm._s(
              _vm.utils.formatLang(
                _vm.$i18n.locale,
                _vm.rankDiaItem.rankCnTitle,
                _vm.rankDiaItem.rankEnTitle
              )
            )}}),_c('p',{domProps:{"innerHTML":_vm._s(
              _vm.utils.formatLang(
                _vm.$i18n.locale,
                _vm.rankDiaItem.rankCnContent,
                _vm.rankDiaItem.rankEnContent
              )
            )}})])],1)])],1):_vm._e(),(!_vm.pageDetailHide)?_c('router-view'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }