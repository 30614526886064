<template>
  <section>
    <div v-show="pageDetailHide" v-if="refreshStatus">
      <div class="edp-rank-out">
        <edp-header-search
          :headerLeftWord="$t('rank.headerLeftWord')"
          :searchPlaceHolder="$t('rank.searchPlaceholder')"
          noSearch="true"
        >
          <div slot="bottom">
            <rank-top :list="rankTopList" @changeRankType="changeRankTypeFn" />
          </div>
        </edp-header-search>

        <div class="topTips fac">
          <img src="@/src/assets/member/notice.png" alt="" class="tip" />
          <span class="f14 op5"> {{ $t("rank.pageTipsWord") }}</span>

          <div class="topTips__right" v-if="rankTopSelected == 2">
            {{ $t("rank.reportTime") }}
          </div>
        </div>

        <rank-cate
          :list="rankCateList"
          v-if="rankTopSelected == 2"
          class="edp-rank-out__sk"
        />

        <member-rank-board
          ref="memberRl"
          v-if="memberDepartSelected === 'member' && rankTopSelected != 2"
          @qes_click="qes_clickFn"
          class="edp-rank-out__fx"
        />

        <div
          class="edp-rank-frame"
          v-if="memberDepartSelected === 'member' && rankTopSelected == 2"
        >
          <iframe :src="`${urls}board`"></iframe>
        </div>

        <div
          class="edp-rank-frame"
          v-if="memberDepartSelected === 'depart' && rankTopSelected == 2"
        >
          <iframe :src="`${urls}departmentBoard`"></iframe>
        </div>
      </div>

      <edp-dialog ref="qesDialog" class="rank-dialog">
        <div slot="content" class="qesDialog-article">
          <edpWaterBg percent="20%" opacity="0.6" />

          <div style="position: relative; width: 100%; z-index: 1">
            <em
              v-html="
                utils.formatLang(
                  $i18n.locale,
                  rankDiaItem.rankCnTitle,
                  rankDiaItem.rankEnTitle
                )
              "
            ></em>

            <p
              v-html="
                utils.formatLang(
                  $i18n.locale,
                  rankDiaItem.rankCnContent,
                  rankDiaItem.rankEnContent
                )
              "
            ></p>
          </div>
        </div>
      </edp-dialog>
    </div>

    <router-view v-if="!pageDetailHide" />
  </section>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";

import memberRankBoard from "../components/rank/memberRankBoard.vue";

import RankCate from "../components/rank/rankCate.vue";
import RankTop from "../components/rank/rankTop.vue";

export default {
  name: "rankBoard",
  components: {
    RankTop,
    RankCate,
    memberRankBoard,
  },
  data() {
    return {
      rankTopList: [],
      rankCateList: [],
      rankDiaIndex: 0,
      rankDiaItem: {},
      urls: "",
      sessionId: "",

      pageDetailHide: true,
      pageFirstVisit: true,
      refreshStatus: false,
    };
  },
  computed: {
    ...mapState({
      rankTopSelected: (state) => state.rankingList.rankTopSelected,
      memberDepartSelected: (state) => state.rankingList.memberDepartSelected,
      userInfoMap: (state) => state.login.userInfo,
    }),
  },
  watch: {
    $route(val) {
      if (val.path.indexOf("memberDetail") != -1) {
        this.pageDetailHide = false;
      } else {
        this.pageDetailHide = true;

        if (this.pageFirstVisit) {
          this.pageFirstVisit = false;
          this.refreshStatus = true;

          this.changeRankTypeFn({ index: 0 });
          let sstUs = setInterval(() => {
            if (this.userInfoMap && this.userInfoMap.sessionId) {
              this.sessionId = this.userInfoMap.sessionId;
              this.urls = `${process.env.VUE_APP_360URL}?sessionid=${this.sessionId}&target=`;
              clearInterval(sstUs);
            }
          }, 100);
        }
      }
    },
  },
  methods: {
    changeRankTypeFn(data) {
      this.$store.commit("rankingList/set_memberDepartSelected", "member");

      if (data.index == 0 || data.index == 1) {
        setTimeout(() => {
          this.$refs.memberRl.pageInit();
        }, 10);
      }
    },
    qes_clickFn(data) {
      this.rankDiaIndex = data.index;
      this.rankDiaItem = data.item;
      this.$refs.qesDialog.show();

      this.$forceUpdate();
    },
  },
  created() {
    this.$store.commit("rankingList/set_rankTopSelected", 0);
    this.$store.commit("rankingList/set_memberDepartSelected", "member");

    this.rankTopList = [
      { name: this.$t("rank.rankTopItem1") },
      { name: this.$t("rank.rankTopItem2") },
      { name: this.$t("rank.rankTopItem3") },
    ];

    this.rankCateList = [
      { name: this.$t("rank.rankCateItem1"), type: "member" },
      { name: this.$t("rank.rankCateItem2"), type: "depart" },
    ];
  },
  mounted() {
    if (this.$route.path.indexOf("memberDetail") != -1) {
      this.pageDetailHide = false;
    } else {
      this.pageDetailHide = true;

      if (this.pageFirstVisit) {
        this.pageFirstVisit = false;
        this.refreshStatus = true;

        this.changeRankTypeFn({ index: 0 });
        let sstUs = setInterval(() => {
          if (this.userInfoMap && this.userInfoMap.sessionId) {
            this.sessionId = this.userInfoMap.sessionId;
            this.urls = `${process.env.VUE_APP_360URL}?sessionid=${this.sessionId}&target=`;
            clearInterval(sstUs);
          }
        }, 100);
      }
    }
  },
};
</script>

<style lang="scss">
@import "@/src/styles/variable.scss";

.edp-rank-out {
  display: flex;
  width: 100%;
  height: 100vh;
  flex-direction: column;
}
.edp-rank-out__sk {
  flex-shrink: 0;
}
.edp-rank-out__fx {
  flex-basis: 100%;
}
.rankCircle {
  display: flex;
  width: 100%;
  padding: toPad(15) 0;
  justify-content: center;

  .swiper-pagination-bullet {
    width: toPad(7);
    height: toPad(7);
    border-radius: 100%;
    background: #d9d9d9;
    margin: 0 toPad(3);

    &.swiper-pagination-bullet-active {
      background: #61bfb4;
    }
  }
}
.qesDialog-article {
  height: auto;
  max-height: toPad(400);
  overflow-y: auto;
  margin: toPad(10) 0 toPad(20) 0;
  padding: 0 toPad(40);

  em {
    display: block;
    width: 100%;
    font-size: toPad(18);
    font-style: normal;
    font-weight: bold;
    white-space: pre-wrap;
  }
  p {
    display: block;
    width: 100%;
    padding-top: toPad(30);
    font-size: toPad(16);
    line-height: 1.5;
    white-space: pre-wrap;
  }
}
.edp-rank__departBottomRemarks {
  width: 100%;
  font-size: toPad(14);
  padding: 0 toPad(40) toPad(20) toPad(40);
}
.rank-dialog {
  width: toPad(580) !important;
  background: url("~@/src/assets/rank/rank-dialog-bg.png") no-repeat 0 0 #fff;
  background-size: 100% auto;
}

.edp-rank-frame {
  position: relative;
  width: 100%;
  flex-basis: 100%;
  overflow: hidden;
  margin-bottom: toPad(20);

  iframe {
    position: relative;
    width: 100%;
    height: 100%;
    background: transparent;
    border: 0;
  }
}
</style>
