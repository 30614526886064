<template>
  <section class="edp-common-content edp-rankingList--member">
    <div class="edp-rankingList--member__blank"></div>

    <div class="edp-rankingList--member__content">
      <swiper :options="swiperOptions" :ref="'mySwiper'">
        <swiper-slide
          v-for="(item, index) in mySwiper_list"
          :key="'mySwiper_rank' + index"
          class="edp-rank__swiper__slide"
        >
          <div class="edp-rank__swiper__slide__item bf-op">
            <section
              class="ersItem-title"
              :class="{ 'asc-title': paramsArray[index].colorRank === 'black' }"
            >
              <div class="ersItem-title__label">
                <label
                  v-html="
                    utils.formatLang(
                      $i18n.locale,
                      item.rankCname,
                      item.rankEname
                    )
                  "
                ></label>
                <i @click="qes_click(index, item)"></i>
              </div>

              <em @click="sortThis(index)">{{ $t("rank.rankSort") }}</em>
            </section>

            <section
              class="ersItem-content"
              v-infinite-scroll="loadMoreList"
              :infinite-scroll-distance="10"
              :infinite-scroll-disabled="!moreLoadingPd"
              ref="rankScrollRef"
              v-show="item.list && item.list.length > 0"
            >
              <div style="width: 100%">
                <div
                  class="ersItem-content__item"
                  v-for="(itemListItem, itemListIndex) in item.list"
                  :key="'ersItemListItem' + itemListIndex"
                  :class="{ rankItemFirst: itemListIndex == 0 }"
                  @click="linkMember(itemListItem)"
                >
                  <div class="ersItem-content__sortNum">
                    {{ itemListIndex + 1 }}
                  </div>

                  <div class="ersItem-content__imgContent">
                    <img :src="itemListItem.photo || defaultHeadImage" alt="" />

                    <img
                      src="@/src/assets/rank/rank-one.png"
                      alt=""
                      class="ersItem-content__imgContent__first"
                      v-if="itemListIndex == 0"
                    />
                  </div>

                  <div class="ersItem-content__detail">
                    <em>{{
                      $i18n.locale === "cn"
                        ? itemListItem.cname || itemListItem.ename
                        : itemListItem.ename || itemListItem.cname
                    }}</em>
                    <p>
                      {{
                        $i18n.locale === "cn"
                          ? itemListItem.jobPositionCn ||
                            itemListItem.jobPosition
                          : itemListItem.jobPosition ||
                            itemListItem.jobPositionCn
                      }}
                    </p>
                  </div>

                  <div class="ersItem-content__numDetail">
                    <em>{{
                      (item.rankCnUnit && item.rankCnUnit === "元") ||
                      (item.rankEnUnit &&
                        item.rankEnUnit.toLowerCase() === "yuan")
                        ? utils.formatMoney(itemListItem.rankCount)
                        : itemListItem.rankCount
                    }}</em>
                    <p>
                      {{
                        utils.formatLang(
                          $i18n.locale,
                          item.rankCnUnit,
                          item.rankEnUnit
                        )
                      }}
                    </p>
                  </div>
                </div>
              </div>
            </section>

            <section
              class="ersItem-commingSoon"
              v-if="(!item.list || item.list.length == 0) && !subLoading"
            >
              {{ $t("rank.commingSoon") }}
            </section>
          </div>
        </swiper-slide>
      </swiper>
    </div>

    <div
      class="rankCircle edp-rank-out__sk"
      id="memberRankCircle"
      v-show="rankTopSelected != 2"
    ></div>

    <van-overlay
      :show="subLoading"
      z-index="120"
      :custom-style="{ background: 'rgba(0,0,0,0.2)' }"
    >
      <div class="loading-wrap-common">
        <van-loading size="50px" color="#3E886D" text-color="#3E886D" vertical
          >&nbsp;</van-loading
        >
      </div>
    </van-overlay>
  </section>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
export default {
  name: "memberRankBoard",
  created() {
    if (this.page_platform === "windows") {
      let windowWidth =
        document.documentElement.clientWidth || document.body.clientWidth;

      if (windowWidth > 1600) {
        this.swiperOptions.slidesPerView = 4.1;
      }
    }
  },
  data() {
    return {
      subLoading: false,
      swiperOptions: {
        slidesPerView: 3.1,
        spaceBetween: 20,
        pagination: {
          el: "#memberRankCircle",
          clickable: true,
        },
      },
      paramsArray: [],
      mySwiper_list: [],
      moreLoadingPd: false,

      pageNum: 1,
      pageSize: 20,
    };
  },
  computed: {
    ...mapState({
      rankTopSelected: (state) => state.rankingList.rankTopSelected,
      defaultHeadImage: (state) => state.login.defaultHeadImage,
      page_platform: (state) => state.login.page_platform,
    }),
  },
  methods: {
    ...mapActions({
      rank_front: "rankingList/rank_front",
      rank_activeApi: "rankingList/rank_activeApi",
    }),
    async pageInit() {
      this.moreLoadingPd = false;
      this.subLoading = true;
      this.paramsArray = [];
      this.mySwiper_list = [];

      let initpars = 0;

      if (this.rankTopSelected == 0) {
        initpars = 1;
      } else if (this.rankTopSelected == 1) {
        initpars = 2;
      } else {
        initpars = 3;
      }

      let resinitpars = await this.rank_front({ rankType: initpars });

      let lsResData =
        resinitpars.data && resinitpars.data.data ? resinitpars.data.data : [];

      lsResData.forEach(async (valLr, eLr) => {
        this.paramsArray.push({
          current: this.pageNum,
          size: this.pageSize,
          sorted: valLr.rankCollation,
          colorRank: valLr.rankRedBlack == 1 ? "red" : "black",
        });

        let lsthisitem = Object.assign(valLr, { list: [] });

        this.mySwiper_list.push(lsthisitem);
      });

      lsResData.forEach(async (valLr, eLr) => {
        let paramsApi = {
          url:
            process.env.NODE_ENV === "development"
              ? "api/" + valLr.rankApiUrl
              : valLr.rankApiUrl,
          params: this.paramsArray[eLr],
        };
        let resApi = await this.rank_activeApi(paramsApi);

        this.mySwiper_list[eLr].list =
          resApi.data && resApi.data.data ? resApi.data.data.records : [];
      });

      this.subLoading = false;

      setTimeout(() => {
        this.moreLoadingPd = true;
      }, 200);

      this.$forceUpdate();
    },
    qes_click(index, item) {
      let params = {
        index: index,
        item: item,
      };
      this.$emit("qes_click", params);
    },
    async sortThis(index, typeMore) {
      this.moreLoadingPd = false;

      if (!typeMore) {
        this.paramsArray[index].sorted === "asc"
          ? (this.paramsArray[index].sorted = "desc")
          : (this.paramsArray[index].sorted = "asc");

        this.paramsArray[index].colorRank === "black"
          ? (this.paramsArray[index].colorRank = "red")
          : (this.paramsArray[index].colorRank = "black");

        this.paramsArray[index].current = 1;
      } else {
        this.paramsArray[index].current = this.paramsArray[index].current + 1;
      }

      let paramsApi = {
        url:
          process.env.NODE_ENV === "development"
            ? "api/" + this.mySwiper_list[index].rankApiUrl
            : this.mySwiper_list[index].rankApiUrl,
        params: this.paramsArray[index],
      };

      let res = await this.rank_activeApi(paramsApi);

      let dataResLs = res.data && res.data.data ? res.data.data.records : [];

      if (!typeMore) {
        this.mySwiper_list[index].list = dataResLs;

        this.$refs.rankScrollRef[index].scrollTo(0, 0);
      } else {
        this.mySwiper_list[index].list =
          this.mySwiper_list[index].list.concat(dataResLs);
      }

      if (dataResLs && dataResLs.length > 0) {
        this.moreLoadingPd = true;
      }

      this.$forceUpdate();
    },
    loadMoreList() {
      if (this.moreLoadingPd) {
        this.paramsArray.forEach((val, e) => {
          this.sortThis(e, "more");
        });
      }
    },
    linkMember(item) {
      let sercetCd = this.utils.encryption(item.cdsid);
      this.$router.push({
        path: "/rankBoard/memberDetail",
        query: { id: sercetCd },
      });
    },
  },

  mounted() {},
};
</script>

<style lang="scss">
@import "@/src/styles/variable.scss";

.edp-rankingList--member {
  width: 100%;
  padding: 0 0 toPad(16) toPad(40);
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .edp-rankingList--member__blank {
    width: 100%;
    height: toPad(20);
    overflow: hidden;
    background: transparent;
    flex-shrink: 0;
  }

  .edp-rankingList--member__content {
    flex-basis: 100%;
    overflow: hidden;
  }

  .swiper-container {
    height: 100%;
  }
  .edp-rank__swiper__slide {
    width: 100%;
    height: 100%;
  }
  .edp-rank__swiper__slide__item {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background: #fff;
    box-shadow: 0 toPad(4) toPad(13) rgba(230, 236, 244, 0.6);
    border-radius: toPad(10);

    .ersItem-title {
      position: relative;
      display: flex;
      width: 100%;
      padding: toPad(20) toPad(20) toPad(24) toPad(20);
      align-items: flex-start;
      line-height: 1;
      flex-shrink: 0;
      z-index: 0;
      font-family: JLREmeric;

      background: linear-gradient(
        180deg,
        rgba(97, 191, 180, 0.31) 0%,
        rgba(255, 255, 255, 0) 100%
      );
      border-radius: toPad(10) toPad(10) 0 0;

      &.asc-title {
        background: linear-gradient(
          180deg,
          rgba(130, 130, 130, 0.28) 0%,
          rgba(255, 255, 255, 0) 100%
        );
      }

      .ersItem-title__label {
        display: flex;
        flex-basis: 100%;
        justify-content: flex-start;
        line-height: 1.4;
      }

      label {
        display: inline-block;
        font-size: toPad(18);
        font-weight: 600;
        white-space: pre-wrap;
        height: toPad(50);
        overflow: hidden;
      }
      i {
        display: inline-block;
        width: toPad(16);
        height: toPad(16);
        cursor: pointer;
        background: url("~@/src/assets/rank/question.png") no-repeat center
          center;
        background-size: 100% 100%;
        margin-left: toPad(10);
        margin-right: toPad(10);
        margin-top: toPad(6);
        flex-shrink: 0;
      }
      em {
        display: inline-block;
        flex-shrink: 0;
        text-align: right;
        font-size: toPad(14);
        padding-right: toPad(20);
        min-height: toPad(16);
        background: url("~@/src/assets/rank/sort.png") no-repeat right center;
        background-size: auto toPad(16);
        cursor: pointer;
        font-style: normal;
        margin-top: toPad(6);
      }
    }
  }

  .ersItem-commingSoon {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-basis: 100%;
    font-weight: bold;
    font-size: toPad(16);
    color: #666;
  }
  .ersItem-content {
    position: relative;
    flex-basis: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 1;
    padding-top: toPad(6);
  }
  .ersItem-content__item {
    display: flex;
    max-width: 100%;
    margin: 0 toPad(20);
    align-items: center;
    padding: toPad(15) 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    cursor: pointer;

    &.rankItemFirst {
      .ersItem-content__imgContent {
        img {
          border: toPad(2) solid #feb64e;
        }
      }
    }
  }
  .ersItem-content__sortNum {
    width: toPad(34);
    font-size: toPad(18);
    flex-shrink: 0;
  }
  .ersItem-content__imgContent {
    width: toPad(60);
    height: toPad(60);
    flex-shrink: 0;
    margin-right: toPad(13);
    position: relative;
    background: #eee;
    border-radius: 100%;

    img {
      position: relative;
      width: 100%;
      height: 100%;
      border-radius: 100%;
      z-index: 1;
    }
    .ersItem-content__imgContent__first {
      border: 0 !important;
      width: toPad(25) !important;
      height: auto !important;
      position: absolute !important;
      left: 50% !important;
      transform: translateX(-50%) !important;
      top: toPad(-20);
      border-radius: 0 !important;
      z-index: 0;
    }
  }
  .ersItem-content__detail {
    flex-basis: 100%;
    overflow: hidden;

    em {
      display: block;
      width: 100%;
      font-style: normal;
      font-size: toPad(18);
      line-height: 1.4;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    p {
      display: block;
      width: 100%;
      font-size: toPad(13);
      opacity: 0.5;
      line-height: 1.4;
      padding-top: toPad(3);
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  .ersItem-content__numDetail {
    flex-shrink: 0;
    display: flex;
    align-items: flex-end;
    line-height: 1;

    em {
      font-size: toPad(26);
      color: #61bfb4;
      font-family: JLREmeric;
      font-style: normal;
      font-weight: bold;
    }
    p {
      position: relative;
      font-size: toPad(13);
      opacity: 0.5;
      top: toPad(-4);
    }
  }
}
</style>
